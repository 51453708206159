
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { ElMessageBox } from 'element-plus'
import { wuRequest } from '@/service'
import Lb from '@/components/Lb.vue'

import { url } from '@/service/config'

import { useRouter } from 'vue-router'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    components: { Lb },
    setup() {
        let t = reactive<any>({
            data: {},
            url
        })
        let router = useRouter()

        onMounted(async () => {
            // 保持菜单更新一直在顶
            document.body.scrollTop = document.documentElement.scrollTop = 0
            let res = await wuRequest.get({
                url: '/new-list'
            })
            if (res.data.code === 10001) {
                t.data = res.data.data.data
            } else {
                ElMessageBox.alert('数据请求错误，请稍后重试', '数据异常', {
                    confirmButtonText: 'OK'
                })
                return false
            }
        })
        const linkTo = (id: number) => {
            router.push('/news-desc/' + id)
        }
        return { ...toRefs(t), linkTo }
    }
})
